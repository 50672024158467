/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBrokerRegaInfo, getWLFooter, getWLProfile, saveWLFooter } from "../../api/userApi";
// import { ReactComponent as WhatsappLogo } from "../../assets/media/icons/whatsapp.svg";
import { WhatsApp } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Button, Dialog, DialogContent, DialogTitle, TextField, Switch, FormControlLabel } from "@mui/material";
import ColorPicker from "../../scenes/admin/styles/components/color-picker";
import LoadingButton from "./loading-btn";
import { resolvePersianAndArabicNumbers, trimPhone } from "../../helpers";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
// import MapContainer from '../googleMapComponent';
import { styled } from "@mui/material/styles";
import { useAuth } from "../../helpers/context";
import { Marker, Tel, Twitter } from "../../constants/icons";
// import GoogleMapsContainerForAdmin from "../admin/components/GoogleMapsComponentForAdmin";
import GoogleMapsContainer from "../section-components/registration/GoogleMapsContainer";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, CORPORATE_LICENSE_URI, DARK_TEMPLATES, DEMO_VENDORS, INDIVIDUAL_LICENSE_URI, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

const MapSwitcher = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      backgroundColor: "var(--main-color-one)",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundColor: "var(--main-color-one)",
        borderRadius: "100%",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
  },
}));
const publicUrl = process.env.REACT_APP_URL + "/";

export default function Footer_v2(props) {
  const [userInfo, setuserInfo] = useState({});
  const [data, setData] = useState({
    latitude: '',
    longitude: '',
    address: '',
    showMap: '0'
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [advertiserNumber, setAdvertiserNumber] = useState("");
  const [commercialRecordNumber, setCommercialRecordNumber] = useState("");
  // const [licenseNumber, setLicenseNumber] = useState("");
  const [name, setName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [oldLocation, setOldLocation] = useState({
    address: '',
    longitude: '',
    latitude: ''
  });
  const { AccountType, vendor, logo, templateId } = useAuth();
  const [openMapModal, setopenMapModal] = useState(false);
  const { t, i18n } = useTranslation();
  const [Location, setLocation] = useState({
    id: null,
    userIdHashed: "",
    address: "",
    latitude: "24.74772941029266",
    longitude: "46.619290983645406",
    isLocationChanged: "0",
    language: i18n.language === 'ar' ? '0' : '1',
    neighborhood: "",
  });
  const [licenseId, setLicenseId] = useState();
  const [licenseNumber, setLicenseNumber] = useState();


  useEffect(() => {
    if (vendor) {
      getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setAdvertiserNumber(_userInfo.response.wlUser.advertiserNumber);
          setCommercialRecordNumber(_userInfo.response.wlUser.commercialRecordNumber);
          // setLicenseNumber(_userInfo.response.wlUser.licenseNumber);
          setName(_userInfo.response.wlUser.briefDescription);
          setManagerName(_userInfo.response.wlUser.managerName);
          setuserInfo(_userInfo.response.wlUser);
          if (_userInfo.response.wlUser.advertiserNumber)
            getBrokerRegaInfo({
              licenseNumber: _userInfo.response.wlUser.advertiserNumber,
              // licenseType: "Bml"
            }).then(res => {
              if (res.resCode === 0) {
                setLicenseId(res.response.id);
                setLicenseNumber(res.response.licenseNumber);
              } else {
                setLicenseId(undefined);
                setLicenseNumber(undefined);
              }
            }).catch(err => {
              setLicenseId(undefined);
              setLicenseNumber(undefined);
            });
        }
      });
      if (DEMO_VENDORS.includes(vendor) && localStorage.getItem(`footer-${vendor}`)) {
        const footerStorage = JSON.parse(localStorage.getItem(`footer-${vendor}`));
        setData({
          ...footerStorage,
          backgroundColor: footerStorage.backgroundColor ? footerStorage.backgroundColor : DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : '#efefef'
        });
        setOldLocation({
          address: footerStorage.address,
          latitude: footerStorage.latitude,
          longitude: footerStorage.longitude
        });
      } else {
        getWLFooter({
          vendorName: vendor,
          language: i18n.language === 'ar' ? '0' : '1',
        }).then((res) => {
          if (res.resCode === 0) {
            setData({
              ...res.response,
              backgroundColor: res.response.backgroundColor ? res.response.backgroundColor : DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : '#efefef'
            });

            setOldLocation({
              address: res.response.address,
              latitude: res.response.latitude,
              longitude: res.response.longitude
            });

            if (DEMO_VENDORS.includes(vendor)) {
              localStorage?.setItem(`footer-${vendor}`, JSON.stringify(res.response));
            }
          }
        });
      }

    }
   
    // getSectionItems({ userName: vendorName, language: "0" }).then(
    //   (_userSection) => {
    //     if (_userSection.resCode === 0) {
    //       const sections = _userSection.response;
    //       sections?.map(item => {
    //         if (item.name === "brokerLocation") {
    //           setLocation({
    //             long: item?.details?.long,
    //             lat: item?.details?.lat,
    //           });
    //         }
    //         return null;
    //       });
    //     }
    //   }
    // );
  },// eslint-disable-next-line
    [vendor]);

  const handleSave = (onlyMap = false) => {
    if (DEMO_VENDORS.includes(vendor)) {
      localStorage?.setItem(`footer-${vendor}`, JSON.stringify(data));
      toast.success(onlyMap ? t("TheLocationsHasBeenSavedSuccessfully") : t("EditedSuccessfully"));
      if (onlyMap)
        setopenMapModal(false);
    } else {
      setLoadingButton(true);
      saveWLFooter({
        userId: props.token,
        language: i18n.language === 'ar' ? '0' : '1',
        description: data.description || ' ',
        backgroundColor: data.backgroundColor,
        showMap: data.showMap || '0',
        latitude: data.latitude,
        longitude: data.longitude,
        address: data.address
      })
        .then((res) => {
          if (res.resCode === 0) {
            toast.success(onlyMap ? t("TheLocationsHasBeenSavedSuccessfully") : t("EditedSuccessfully"));
            getWLFooter({
              vendorName: vendor,
              language: i18n.language === 'ar' ? '0' : '1',
            }).then((res) => {
              if (res.resCode === 0) {
                setData(res.response);
                setOldLocation({
                  address: res.response.address,
                  latitude: res.response.latitude,
                  longitude: res.response.longitude
                });
                if (onlyMap)
                  setopenMapModal(false);
              }
            });
          }
          setLoadingButton(false);
        })
        .catch((e) => setLoadingButton(false));
    }
   
  };

  return (
    <div className='d-flex flex-column'>
      {props.isEdit && (
        <div className="row align-items-center justify-content-start" style={{ flexWrap: 'wrap' }}>
          <div className='mb-4 mx-3'>
            {data.backgroundColor && (
              <ColorPicker
                name={t("BackgroundColor")}
                onChange={(color) => setData({ ...data, backgroundColor: color })}
                color={data.backgroundColor}
              />
            )}
          </div>
          {AccountType === '2' && (<>
          
            <div className='mb-4 mx-3 d-flex align-items-center'>
              {t("ViewCompanyLocation")}
              <FormControlLabel
                style={{ margin: 0 }}
                onChange={() => setData({ ...data, showMap: data.showMap === '1' ? '0' : '1' })}
                checked={data.showMap === '1'}
                control={<MapSwitcher />}
              />
            </div>
            <div className='mb-4 mx-3 d-flex align-items-center'>
              <Button
                className="primary-btn py-2 px-3"
                onClick={() => setopenMapModal(true)}
              >
                <Marker /> {t("LocateTheCompany")}
              </Button>
            </div>
          </>
             
          )}
        
        </div>
      )}
      {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ?
        <footer className={`footer-area v3 ${props.isEdit ? "admin col-12" : ""}`} style={{ background: `${data.backgroundColor}` }}>
          <img src="/assets/img/bg-footer.png" className="bg" alt="footer bakground" />
          <div className='footer-wrapper'>
            <div className="footer-top">
              <Link to="/">
                <img src={localStorage.getItem(`logo-${vendor}`) ? localStorage.getItem(`logo-${vendor}`) : logo} alt="logo" />
              </Link>
              <ul>
                <li key='1'>
                  <Link to={"/about"}>{t("AboutUS")}</Link>
                </li>
                <li key='2'>
                  <Link to={"/contact"}>{t("ContactUs")}</Link>
                </li>
                <li key='3'>
                  <Link to={"/privacy-policy"}>{t("PrivacyPolicy")}</Link>
                </li>
                <li key='4'>
                  <Link to={"/intellectual-property-rights"}>{t("IntellectualPropertyRights")}</Link>
                </li>
                <li key='5'>
                  <Link to={"/terms"}>{t("TermsOfUse")}</Link>
                </li>
              </ul>
              <ul className={`social-icon`}>
                {userInfo.facebook &&
                  <li>
                    <a href={"https://www.facebook.com/" + userInfo.facebook} target='_blank' rel='noreferrer'>
                      <FacebookIcon sx={{ fontSize: '34px', cursor: 'pointer' }} />
                    </a>
                  </li>
                }
                {userInfo.instagram &&
                  <li>
                    <a href={"https://www.instagram.com/" + userInfo.instagram} target='_blank' rel='noreferrer'>
                      <InstagramIcon sx={{ fontSize: '34px', cursor: 'pointer' }} />
                    </a>
                  </li>
                }
                {userInfo.twitter &&
                  <li>
                    <a href={"https://twitter.com/" + userInfo.twitter} target='_blank' rel='noreferrer'>
                      <Twitter />
                    </a>
                  </li>
                }
                {userInfo.whatsapp && (
                  <li>
                    <a target='_blank' rel='noreferrer' href={"https://wa.me/+" + userInfo.whatsapp}>
                      <WhatsApp sx={{ fontSize: '34px', cursor: 'pointer' }} />
                    </a>
                  </li>
                )}
                {DARK_TEMPLATES.includes(+templateId) && (
                  <>
                    {userInfo.phone && (
                      <li>
                        <a className="custom-icon" target='_blank' rel='noreferrer' href={"tel:" + trimPhone(userInfo.phone)}>
                          <Tel />
                        </a>
                      </li>
                    )}
                    {data.latitude && data.showMap === '1' && (
                      <li>
                        <a className="custom-icon" target='_blank' rel='noreferrer' href={`https://maps.google.com/?q=${data.latitude},${data.longitude}`}>
                          <Marker />
                        </a>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
            <div className='footer-center'>
              <div
                className={`row m-0 justify-content-center justify-content-lg-between`}>
            
         
                <div className={`p-0 col-md-5 col-12 d-flex justify-content-center ${DARK_TEMPLATES.includes(+templateId) ? '' : 'justify-content-md-start'}`}>
                  <div
                    className={`widget widget_nav_menu align-items-center text-center text-md-start`}>
                    <h5 className={`text-center custom-link ${DARK_TEMPLATES.includes(+templateId) ? '' : 'text-md-start'}`}>
                      {window.location.href.includes('amakkncompany.com') ? 'مكتب أحمد للعقارات (مثال)' : name}
                    </h5>
                    {managerName && (
                      <h5 style={{ fontWeight: 400, fontSize: 15 }}
                        className={`text-center ${DARK_TEMPLATES.includes(+templateId) ? '' : 'text-md-start'}`}>
                        {window.location.href.includes('amakkncompany.com') ? 'خالد فهد عبدالله' : managerName}
                      </h5>
                    )}
                    {advertiserNumber && !window.location.href.includes('amakkncompany.com') && (
                      <h5 className={`text-center ${DARK_TEMPLATES.includes(+templateId) ? '' : 'text-md-start'}`} style={{ fontWeight: 400, fontSize: 16 }}>
                        {t("FalLicense")} &nbsp;
                        <span style={{ fontWeight: 900 }}>
                          {licenseId ? (
                            <a target="_blank"
                              style={{ textDecoration: 'underline', color: 'var(--main-color-one)' }}
                              rel="noreferrer"
                              href={+userInfo.accountType === 1 ? INDIVIDUAL_LICENSE_URI + licenseId : CORPORATE_LICENSE_URI + licenseId}>
                              {licenseNumber}

                            </a>
                          ) : <span>
                            {advertiserNumber}
                          </span>}
                        </span>
                      </h5>
                    )}
                   
                    {window.location.href.includes('amakkncompany.com') && (<>
                      <h5 className={`text-center ${DARK_TEMPLATES.includes(+templateId) ? '' : 'text-md-start'}`} style={{ fontWeight: 400, fontSize: 16 }}>
                        {t("CommercialRegistrationNo")} &nbsp;
                        <span style={{ fontWeight: 900 }}>123456789</span>
                      </h5>
                      <h5 className={`text-center ${DARK_TEMPLATES.includes(+templateId) ? '' : 'text-md-start'}`} style={{ fontWeight: 400, fontSize: 16 }}>
                        {t("CompanyUnifiedNationalNumber")} &nbsp;
                        <span style={{ fontWeight: 900 }}>700123456</span>
                      </h5>
                    </>)}

                    {commercialRecordNumber && !window.location.href.includes('amakkncompany.com') && (
                      <h5 className={`text-center ${DARK_TEMPLATES.includes(+templateId) ? '' : 'text-md-start'}`} style={{ fontWeight: 400, fontSize: 16 }}>
                        {commercialRecordNumber?.startsWith('7') ? t("CompanyUnifiedNationalNumber") : t("CommercialRegistrationNo")}&nbsp;
                        <span style={{ fontWeight: 900 }}>{commercialRecordNumber}</span>
                      </h5>
                    )}

                    {props.isEdit ? (
                      <>
                        <TextField
                          label={t("Description")}
                          sx={{ width: "100%", marginTop: '10px' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 300 }}
                          value={data.description}
                          size='small'
                          multiline
                          rows={6}
                          onChange={(v) => {
                            setData({
                              ...data,
                              description: resolvePersianAndArabicNumbers(
                                v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value
                              ),
                            });
                          }}
                        />
                        <h6 className='rightalign mt-2'>
                          {data.description
                            ? parseInt(data.description.length) + "/300"
                            : "0/300"}
                        </h6>
                      </>
                    ) : (
                      <>
                        <p style={{ marginBottom: '30px', whiteSpace: 'pre-line' }}>{data.description}</p>
                      </>
                    )}
                
                  </div>
                </div>
                {!DARK_TEMPLATES.includes(+templateId) && (
                  <>
                    {/* <div className={`p-0 col-md-3 col-12 d-flex flex-column justify-content-center justify-content-md-start`}>
                      <h5 className="custom-link text-center text-md-start">صفحات الموقع </h5>

                      <ul className='website-links'>
                        <li className='readeal-top' key='1'>
                          <Link to={"/"}>الرئيسية</Link>
                        </li>
                        {+templateId === 4 && <li className='readeal-top' key='2'>
                          <Link to={"/projects"}>{t("OurProjects")}</Link>
                        </li>}
                        <li className='readeal-top' key='3'>
                          <Link to={"/properties"}>إعلاناتنا العقاريّة</Link>
                        </li>
                        <li className='readeal-top' key='4'>
                          <Link to={"/about"}>من نحن</Link>
                        </li>
                        <li className='readeal-top' key='5'>
                          <Link to={"/contact"}>اتصل بنا</Link>
                        </li>
                        <li className='readeal-top' key='6'>
                          <Link to={"/blog"}>المدونة</Link>
                        </li>
                      </ul>
                    </div> */}
             
                    <div
                      className={`p-0 col-md-4 col-12 mt-3 mt-md-0`}>
                      <h5 className="custom-link text-center text-md-start">{t("ContactUs")}</h5>
                      {userInfo && userInfo.phone && (
                        <div>
                          <a
                            className={`reservation-link text-center text-md-start`}
                            href={"tel:" + window.location.href.includes('amakkncompany.com') ? '051234567' : trimPhone(userInfo.phone)}>
                            <span className="text-center text-md-start" style={{ display: 'block' }}>{window.location.href.includes('amakkncompany.com') ? '051234567' : trimPhone(userInfo.phone)}</span>
                          </a>
                  
                          {/* <div className="license-wrapper">
                 <img src="/assets/img/ic_rega_720.svg" />
                 <span>{advertiserNumber}</span>
               </div> */}
                          {data.latitude && data.showMap === '1' && (
                            <>
                              {/* <div className="footer-map mb-0">
                         <MapContainer
                           lat={data.latitude}
                           lng={data.longitude}
                           zoom={12}
                         />
                       </div> */}
                              <span className="text-center text-md-start" style={{ display: 'block', margin: '14px 0' }}>{window.location.href.includes('amakkncompany.com') ? 'حي النخيل, الرياض, المملكة العربيّة السعوديّة' : data.address}</span>
                            </>)}
                        </div>
                      )}
              
                    </div>
                  </>)}
              </div>
            </div>
            {!props.isEdit && (
              <div className='footer-bottom'>
                <p className='text-center text-md-start'>
                  {t("AllRightsReserved")} - {userInfo.briefDescription}{" "}
                  {new Date().getFullYear()} ©
                </p>
                <div>
                  <span style={{ color: DARK_TEMPLATES.includes(+templateId) ? 'var(----paragraph-color)' : '#000', fontSize: '14px', fontWeight: 500 }}>{t("ServiceProvidedBy")}</span>
                  <a href='https://www.amakkn.com/amakkn-pro'>
                    <img
                      src={i18n.language === "ar" ? `/assets/img/logo/amakkn-logo${DARK_TEMPLATES.includes(+templateId) ? '-dark' : ''}.svg` : `/assets/img/amakknLogo.png`}
                      alt='amakkn-logo'
                      style={{
                        position: "relative",
                        top: "-4px",
                        right: i18n.language === "ar" ? '6px' : "unset",
                        marginInline: i18n.language !== "ar" ? '6px' : "0",
                        cursor: "pointer",
                        width: i18n.language === "ar" ? "65px" : '35px',
                      }}
                    />
                    {i18n.language !== "ar" && <span style={{ color: DARK_TEMPLATES.includes(+templateId) ? 'var(----paragraph-color)' : '#000', fontSize: '14px', fontWeight: 500 }}>Amakkn</span>}

                  </a>
                </div>
              </div>
            )}
          </div>
        </footer> :
        <footer
          className={`footer-area whitelabel ${props.isEdit ? "admin col-12" : ""} ${[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'template-3__footer-area' : ''}`}
          style={{ background: `${data.backgroundColor}` }}>
          <div className='footer-wrapper'>
            <div className='footer-center'>
              <div
                className={`row m-0 ${props.isEdit ? "flex-row-reverse" : ""}`}>
                <div
                  className={`order-1 order-md-0 ${[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-md-4 col-lg-3 justify-content-start' : 'col-md-6 justify-content-start'} d-flex align-items-center flex-column  ${props.isEdit ? [...MODERN_TEMPLATES, ...CLASSIC2_TEMPLATES, ...CLASSIC_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? "align-items-md-start p-0" : "align-items-md-end" : [...MODERN_TEMPLATES, ...CLASSIC2_TEMPLATES, ...CLASSIC_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'align-items-md-end' : "align-items-md-start p-0"}`}>
                  {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? <h5 className="custom-link">{t("ContactUs")}</h5> : null}

                  {userInfo && userInfo.phone && (
                    <div>
                      <a
                        className={`reservation-link text-center  ${[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'text-md-start' : 'text-md-left'}`}
                        href={"tel:" + window.location.href.includes('amakkncompany.com') ? '051234567' : trimPhone(userInfo.phone)}>
                        <span>{window.location.href.includes('amakkncompany.com') ? '051234567' : trimPhone(userInfo.phone)}</span>
                      </a>
                      <ul className={`social-icon justify-content-center ${[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'justify-content-md-end' : 'justify-content-md-start'}`}>
                        {userInfo.facebook &&
                          <li>
                            <a href={"https://www.facebook.com/" + userInfo.facebook} target='_blank' rel='noreferrer'>
                              <FacebookIcon sx={{ fontSize: '34px', cursor: 'pointer' }} />
                            </a>
                          </li>
                        }
                        {userInfo.instagram &&
                          <li>
                            <a href={"https://www.instagram.com/" + userInfo.instagram} target='_blank' rel='noreferrer'>
                              <InstagramIcon sx={{ fontSize: '34px', cursor: 'pointer' }} />
                            </a>
                          </li>
                        }
                        {userInfo.twitter &&
                          <li>
                            <a href={"https://twitter.com/" + userInfo.twitter} target='_blank' rel='noreferrer'>
                              <Twitter />
                            </a>
                          </li>
                        }
                        {userInfo.whatsapp && (
                          <li>
                            <a target='_blank' rel='noreferrer' href={"https://wa.me/+" + userInfo.whatsapp}>
                              <WhatsApp sx={{ fontSize: '34px', cursor: 'pointer' }} />
                            </a>
                          </li>
                        )}
                      </ul>
                      {/* <div className="license-wrapper">
                 <img src="/assets/img/ic_rega_720.svg" />
                 <span>{advertiserNumber}</span>
               </div> */}
                      {data.latitude && data.showMap === '1' && (
                        <>
                          {/* <div className="footer-map mb-0">
                         <MapContainer
                           lat={data.latitude}
                           lng={data.longitude}
                           zoom={12}
                         />
                       </div> */}
                          <span style={{ textAlign: [...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'right' : 'center', display: 'flex', margin: '14px auto' }}>{window.location.href.includes('amakkncompany.com') ? 'حي النخيل, الرياض, المملكة العربيّة السعوديّة' : data.address}</span>
                        </>)}
                    </div>
                  )}
              
                </div>
                {/* {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ?
                  <div className={`order-0 order-md-1 p-0 footer-bottom flex-column col-md-4 col-lg-3`}>
                    <h5 className="custom-link">صفحات الموقع</h5>
                    <ul className='order-0 order-md-1 flex-column mx-auto mx-md-0'>
                      <li className='readeal-top' key='1'>
                        <Link to={"/about"}>من نحن</Link>
                      </li>
                      <li className='readeal-top' key='2'>
                        <Link to={"/contact"}>تواصل معنا</Link>
                      </li>
                      <li className='readeal-top' key='3'>
                        <Link to={"/privacy-policy"}>سياسة الخصوصيّة</Link>
                      </li>
                      <li className='readeal-top' key='4'>
                        <Link to={"/intellectual-property-rights"}>الملكيّة الفكريّة</Link>
                      </li>
                      <li className='readeal-top' key='5'>
                        <Link to={"/terms"}>شروط الاستخدام</Link>
                      </li>
                    </ul>
                  </div>
                  : null} */}
           
                <div className={`order-0 order-md-1 p-0 ${[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-md-4 col-lg-6' : 'col-md-6'}`}>
                  <div
                    className={`widget widget_nav_menu align-items-center text-center text-md-start ${props.isEdit ? "align-items-md-start" : "align-items-md-end"}`}>
                    <h4 className='widget-title' style={{ fontWeight: 600 }}>
                      {/* {userInfo.briefDescription} */}{window.location.href.includes('amakkncompany.com') ? 'مكتب أحمد للعقارات (مثال)' : name}
                    </h4>
                    {managerName && (
                      <h5 style={{ fontWeight: 400, fontSize: 15 }}>
                        {window.location.href.includes('amakkncompany.com') ? 'خالد فهد عبدالله' : managerName}
                      </h5>
                   
                    )}
                    {advertiserNumber && !window.location.href.includes('amakkncompany.com') && (
                      <h5 className='text-center text-md-start' style={{ fontWeight: 400, fontSize: 16 }}>
                        {t("FalLicense")} &nbsp;
                        <span style={{ fontWeight: 900 }}>
                          {licenseId ? (
                            <a target="_blank"
                              style={{ textDecoration: 'underline', color: 'var(--main-color-one)' }}
                              rel="noreferrer"
                              href={+userInfo.accountType === 1 ? INDIVIDUAL_LICENSE_URI + licenseId : CORPORATE_LICENSE_URI + licenseId}>
                              {licenseNumber}

                            </a>
                          ) : <span>
                            {advertiserNumber}
                          </span>}
                        </span>
                      </h5>
                    )}
                    {window.location.href.includes('amakkncompany.com') && (<>
                      <h5 className='text-center text-md-start' style={{ fontWeight: 400, fontSize: 16 }}>
                        {t("CommercialRegistrationNo")} &nbsp;
                        <span style={{ fontWeight: 900 }}>123456789</span>
                      </h5>
                      <h5 className='text-center text-md-start' style={{ fontWeight: 400, fontSize: 16 }}>
                        {t("CompanyUnifiedNationalNumber")} &nbsp;
                        <span style={{ fontWeight: 900 }}>700123456</span>
                      </h5>
                    </>)}
                    {commercialRecordNumber && !window.location.href.includes('amakkncompany.com') && (
                      <h5 className='text-center text-md-start' style={{ fontWeight: 400, fontSize: 16 }}>
                        {commercialRecordNumber?.startsWith('7') ? t("CompanyUnifiedNationalNumber") : t("CommercialRegistrationNo")}&nbsp;
                        <span style={{ fontWeight: 900 }}>{commercialRecordNumber}</span>
                      </h5>
                    )}
                    {props.isEdit ? (
                      <>
                        <TextField
                          label={t("Description")}
                          sx={{ width: "100%", marginTop: '10px' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 300 }}
                          value={data.description}
                          size='small'
                          multiline
                          rows={6}
                          onChange={(v) => {
                            setData({
                              ...data,
                              description: resolvePersianAndArabicNumbers(
                                v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value
                              ),
                            });
                          }}
                        />
                        <h6 className='rightalign mt-2'>
                          {data.description
                            ? parseInt(data.description.length) + "/300"
                            : "0/300"}
                        </h6>
                      </>
                    ) : (
                      <>
                        <p style={{ marginBottom: '30px', whiteSpace: 'pre-line' }}>{data.description}</p>
                      </>
                    )}
                
                  </div>
                </div>
              </div>
            </div>
            {!props.isEdit && (
              <>
                <div className='footer-bottom copy-right flex-column flex-md-row align-items-start justify-content-center justify-content-md-between'>
                  <div className={` ${[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'order-1 order-md-0 mx-auto mx-md-0 d-flex justify-content-between w-100' : 'order-1 order-md-0 mx-auto mx-md-0'}`}>
                    <p className='text-center text-md-start'>
                      {t("AllRightsReserved")} - {userInfo.briefDescription}{" "}
                      {new Date().getFullYear()} ©
                    </p>
                    {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? <div
                      className='order-0 order-md-1 flex-column flex-md-row align-items-center mx-auto mx-md-0 justify-content-center justify-content-md-start'
                    >
                      <span>{t("ServiceProvidedBy")}</span>
                      <a href='https://www.amakkn.com/amakkn-pro'>
                        <img
                          src={i18n.language === "ar" ? `/assets/img/logo/amakkn-logo.svg` : `/assets/img/amakknLogo.png`}
                          alt='amakkn-logo'
                          style={{
                            position: "relative",
                            top: "-2px",
                            right: i18n.language === "ar" ? '6px' : "unset",
                            marginInline: i18n.language !== "ar" ? '6px' : "0",
                            cursor: "pointer",
                            width: i18n.language === "ar" ? "65px" : '35px',
                          }}
                        />
                        {i18n.language !== "ar" && <span style={{ color: DARK_TEMPLATES.includes(+templateId) ? 'var(----paragraph-color)' : '#000', fontSize: '14px', fontWeight: 500 }}>Amakkn</span>}

                      </a>
                    </div> : null}
                
                  </div>
                  {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? null : (
                    <ul className='order-0 order-md-1 flex-column flex-md-row align-items-center mx-auto mx-md-0 justify-content-center justify-content-md-start'>
                      <li className='readeal-top' key='1'>
                        <Link to={"/about"}>{t("AboutUS")}</Link>
                      </li>
                      <li className='readeal-top' key='2'>
                        <Link to={"/contact"}>{t("ContactUs")}</Link>
                      </li>
                      <li className='readeal-top' key='3'>
                        <Link to={"/privacy-policy"}>{t("PrivacyPolicy")}</Link>
                      </li>
                      <li className='readeal-top' key='4'>
                        <Link to={"/intellectual-property-rights"}>{t("IntellectualPropertyRights")}</Link>
                      </li>
                      <li className='readeal-top' key='5'>
                        <Link to={"/terms"}>{t("TermsOfUse")}</Link>
                      </li>
                      {/* <li className='readeal-top' key='4'>
                    <a href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/rega.pdf'>
                      ضوابط الإعلانات العقاريّة
                    </a>
                  </li> */}
                    </ul>
                  )}
          
                </div>
                {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? null : <div
                  className='w-100 d-flex align-items-center justify-content-center mb-3'
                >
                  <span>{t("ServiceProvidedBy")}</span>
                  <a href='https://www.amakkn.com/amakkn-pro'>
                    <img
                      src={i18n.language === "ar" ? `/assets/img/logo/amakkn-logo.svg` : `/assets/img/amakknLogo.png`}
                      alt='amakkn-logo'
                      style={{
                        position: "relative",
                        top: "-2px",
                        right: i18n.language === "ar" ? '6px' : "unset",
                        marginInline: i18n.language !== "ar" ? '6px' : "0",
                        cursor: "pointer",
                        width: i18n.language === "ar" ? "65px" : '35px',
                      }}
                    />
                    {i18n.language !== "ar" && <span style={{ color: DARK_TEMPLATES.includes(+templateId) ? 'var(----paragraph-color)' : '#000', fontSize: '14px', fontWeight: 500 }}>Amakkn</span>}
                  </a>
                </div>}
        
              </>
            )}
          </div>
        </footer>
      }
     
      {props.isEdit && (
        <div className='w-100 d-flex justify-content-center mt-4'>
          <LoadingButton
            classes='primary-btn px-3 m-auto'
            handleClick={() => handleSave(false)}
            loading={loadingButton}
            label={t("SaveChanges")}
          />
        </div>
      )}
      {AccountType === '2' && props.isEdit && (
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          open={openMapModal}
          className="custom-dialog"
          sx={{ '& .MuiPaper-root': { margin: 0, width: 'calc(100% - 20px)', padding: 0 } }}
          onClose={() => setopenMapModal(false)}>
          <DialogTitle>
            <div className="dialog-head">
              <div>
                <h2>{t("LocateTheCompany")}</h2>
              </div>
              <div className="dialog-actions">
                <button className="px-3 primary-btn" onClick={() => handleSave(true)}>
                  {t("Save")}
                </button>
                <button className="px-3 outlined-btn" onClick={() => {
                  setopenMapModal(false);
                  setData({ ...data, latitude: oldLocation.latitude, longitude: oldLocation.longitude, address: oldLocation.address })
                }
                }>
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </DialogTitle>
          <DialogContent className='p-0'>
            <div className='p-0'>
              <div style={{ display: 'flex', width: '100%' }}>
                <div className="select-map" style={{ width: '100%', padding: '0', textAlign: 'center', height: '400px', position: 'relative' }}>
                  {/* <GoogleMapsContainerForAdmin
                    data={{
                      lat: data.latitude,
                      long: data.longitude,
                      address: data.address,
                    }}
                setLocation={(e) => {
                  setData({
                    ...data,
                    latitude: e.latitude,
                    address: e.address,
                    longitude: e.longitude,
                  });
                }}
                  /> */}
                  <GoogleMapsContainer
                    lat={data.latitude || '24.716199523004914'}
                    lng={data.longitude || '46.671776478222675'}
                    onChange={(NewLocation) => {
                      setData({
                        ...data,
                        latitude: NewLocation.latitude,
                        longitude: NewLocation.longitude,
                        address: NewLocation.address,
                      });
                      setLocation({
                        ...Location,
                        NewLocation,
                      });
                    }
                    }
                    zoomLevel={9}
                    // onZoomChange={handleZoomChange}
                    user={Location}
                  />
                  <img
                    alt='pin'
                    src={publicUrl + "assets/img/icons/BluePin.svg"}
                    className='addPropPin'
                  />
                  <input
                    id='address'
                    name='address'
                    className='form-control py-3 map-input'
                    value={Location.address}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
